<template>
  <div class="add-vh">
    <div class="add-vh-main">
      <park-header></park-header>
      <div class="add-vh-content">
        <go-back-title :title="'新增黑名单'"></go-back-title>
        <van-form @submit="onSubmit">
          <plate-number :required=true :value.sync="form.plateNumber"></plate-number>
          <!--<vh-cate :required=true :value.sync="form.vhCateId"></vh-cate>-->
          <vh-type :required=true :value.sync="form.vhTypeId"></vh-type>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">增加黑名单</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
// import vhCate from '../../public/vh-cate'
import vhType from '../../public/vh-type'
import plateNumber from '../../public/plate-number'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
import goBackTitle from '../../public/go-back-title'
import { mapState } from 'vuex'
export default {
  name: 'add-vh',
  components: {
    parkHeader,
    goBackTitle,
    // vhCate,
    vhType,
    plateNumber
  },
  data () {
    return {
      show: false,
      isMore: false,
      isLoading: false,
      pattern: new RegExp('^[' +  common.plateNumberHeader.join('') + '][A-Z][A-Z0-9]{5,6}$'),
      form: {
        plateNumber: '',
        chargeFee: 0,
        num: 1,
        vhCateId: '',
        vhTypeId: ''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    onMessage (message) {
      console.log('message', message)
      if (message && message.code == common.resCode.OK) {
        this.$nextTick(() => {
          this.form.vhCateId = 1
          this.form.vhTypeId = 1
          this.form.plateNumber = ''
          this.form.monthlyRentFee = 0
          this.form.ownerName = ''
          this.form.guaranteeDepositFee = 0
          this.form.ownerPhone = ''
        })
        this.$toast.success('添加成功')
      } else {
        this.$toast.fail('添加失败')
      }
    },
    async onSubmit (form) {
      try {
        let para = {
          plate_number: form.plateNumber,
          vh_type_id: this.form.vhTypeId,
          customer_id: localStorage.getItem('customer_id'),
          sid: localStorage.getItem('sid'),
          ...api.getPublicPara('v2')
        }
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_remote_vh_black_add}`;
        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_remote_vh_black_add] = this.onMessage
        let res = await api.axiosApiRequests(api.apiUrlList.vh_black.add_black_vh, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    }
  },
  mounted () {
    new mqtt.Client()
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_remote_vh_black_add}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.mq_remote_vh_black_add]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.add-vh {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .add-vh-content {
      box-sizing: border-box;
      margin: 1rem 1rem 0 1rem;

      .expired-wrap{
        margin: 1rem;

        .expired-title{
          margin-bottom: 1rem;
          text-align: left;
          font-size: $global-font-size-small;
          color: $global-theme-gray;
        }
        .expired-bnt-wrap{
          display: flex;

          .expire-item{
            margin-right: 1rem;
            width: 11rem;
            height: 4.8rem;
            line-height: 4.8rem;
            background: #FAFAFA;
            border-radius: .4rem;
            border: .2rem solid #CCCCCC;
          }
          .expire-item.active {
            color: $global-theme-blue;
            border: .2rem solid $global-theme-blue;
            background: $global-theme-bg-blue;
          }
        }
      }

    }
    .more-img{
      width: 2rem;
      height: 2rem;
      &.up{
        background-image: url("../../assets/up.png");
        background-size: 100% 100%;
      }
      &.down{
        background-image: url("../../assets/down.png");
        background-size: 100% 100%;
      }
    }
  }
  /deep/ .van-stepper--round .van-stepper__minus {
    color: white;
    background-color:$global-theme-blue;
    border: .2rem solid $global-theme-blue;
  }
  /deep/ .van-stepper--round .van-stepper__plus {
    color: #fff;
    background-color: $global-theme-blue;
  }
}
</style>
